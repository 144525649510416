import { IUser } from '@/types'
import { getUserProfile, UserProfile } from 'api/profiles'
import qs from 'qs'
import { useQuery, UseQueryOptions } from 'react-query'
import { useAuth } from '../contexts'

type Query = {
  filters?: object
  populate?: string[]
}

type Config = {
  query?: Query
  options?:
    | Omit<
        UseQueryOptions<
          UserProfile | undefined,
          unknown,
          UserProfile | undefined,
          (string | IUser | undefined)[]
        >,
        'queryKey' | 'queryFn'
      >
    | undefined
}

export const useUserProfile = (
  { query: initialQuery, options }: Config = {
    query: {
      populate: [
        'shippingAddress',
        'billingAddress',
        'buyingCard',
        'senderAddress',
      ],
    },
    options: { refetchOnWindowFocus: false, keepPreviousData: true },
  }
) => {
  const { user } = useAuth()

  const data = useQuery(
    ['user-profile', user],
    async () => {
      if (!user || !user.id) return undefined

      const query = qs.stringify(initialQuery, {
        encodeValuesOnly: true,
      })

      const { error, profile } = await getUserProfile(user.id, query)
      if (!profile || error) {
        console.error(error?.message)
        return undefined
      }
      return profile
    },
    options
  )

  return data
}
